import ThreeDots from "@agney/react-loading/src/svg-loaders/three-dots.svg?react";
import MessageUserIcon from "@locaisolutions/icons/dist/icons20px/Chat20Px";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Chip,
  IconButton,
  Typography,
  Box,
  Stack,
  Tooltip,
  Button
} from "@mui/material";
import { ASTableV2Cell, ASTableV2Row } from "@qubit/autoparts";
import { Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "~/app/store";

import { Recipient } from "~/features/andon/Messenger";
import { AndonWorkstation } from "~/features/andon/andon.slice";
import {
  selectAdminSummariesGrid,
  setErrorViewForWorkstation
} from "~/features/serviceSupport/serviceSupport.slice";
import {
  extractDisplayName,
  extractReceipientId,
  extractUserNameFromAuth0Id
} from "~/lib/helpers";
interface AdminSummariesTableProps {
  activeWorkstation: AndonWorkstation;
  openMessageComponent: (
    event: React.MouseEvent | React.KeyboardEvent,
    newRecipients: Recipient[]
  ) => void;
  portErrorsCount?: number;
  isFetchingPortErrors?: boolean;
}

const AdminSummariesContentRow = (props: AdminSummariesTableProps) => {
  const { activeWorkstation, openMessageComponent } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedGridId = useAppSelector(selectAdminSummariesGrid);

  const { workstation, parentPort, ports, id } = activeWorkstation;
  const { deviceId, userId, mode, roles } = workstation;

  const parentPortsDisplay = parentPort?.portId || "-";
  const portsDisplay = ports?.map((port) => port.portId).join(", ") || "-";
  const workstationMode = mode !== "Undefined" ? mode : "-";

  const goToErrorDetails = () => {
    dispatch(
      setErrorViewForWorkstation({
        workstationId: id,
        autostoreGridId: selectedGridId,
        errorType: "Port Errors"
      })
    );
    navigate("/service-support?service-support-tab=unify");
  };

  return (
    <Fragment key={id}>
      <ASTableV2Row aria-label="active workstation row">
        <ASTableV2Cell>
          <Typography variant="tableBody">{deviceId}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">
            {userId ? extractUserNameFromAuth0Id(userId) : "-"}
          </Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">{parentPortsDisplay}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">{portsDisplay}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">{workstationMode}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Box gap={0.5} display="grid" gridTemplateColumns="repeat(2, auto)">
            {roles.map((role) => (
              <Chip label={role} color="1" key={`aws-${id}-${role}`} />
            ))}
          </Box>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <IconButton
            aria-label="message user"
            onClick={(event) => {
              openMessageComponent(event, [
                {
                  workstationUserId: userId,
                  recipientId: extractReceipientId(userId),
                  displayName: extractDisplayName(userId)
                }
              ]);
            }}
          >
            <MessageUserIcon width={30} height={30} />
          </IconButton>
        </ASTableV2Cell>
        <ASTableV2Cell aria-label="port error count">
          <Stack flexDirection="row" gap={2}>
            <Chip
              color="5"
              label={
                props.isFetchingPortErrors ? (
                  <ThreeDots color="white" width="18" />
                ) : (
                  <Typography variant="tableBody">
                    {props.portErrorsCount || "-"}
                  </Typography>
                )
              }
              sx={{
                borderRadius: "6px",
                minWidth: "40px",
                height: "45px",
                fontSize: "18px"
              }}
            />
            {!!props.portErrorsCount && (
              <Tooltip
                title={t("go to unify port errors")}
                arrow
                placement="bottom"
              >
                <Button
                  variant="subtle"
                  onClick={goToErrorDetails}
                  sx={{
                    minWidth: 40,
                    height: 45,
                    p: 1
                  }}
                  size="small"
                  aria-label="workstations errors view button"
                >
                  <OpenInNewIcon
                    sx={{
                      height: 30,
                      width: 30
                    }}
                  />
                </Button>
              </Tooltip>
            )}
          </Stack>
        </ASTableV2Cell>
      </ASTableV2Row>
    </Fragment>
  );
};

export default AdminSummariesContentRow;
