import {
  BookmarkFill20Px,
  Checkmark20Px,
  CheckmarkCircle20Px,
  GridDesign20Px,
  Search20Px,
  Warning20Px
} from "@locaisolutions/icons";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Collapse
} from "@mui/material";
import { Stack } from "@mui/system";

import { useToast } from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import {
  useCloseBinMutation,
  useDeleteBinFlagMutation
} from "~/redux/warehouse/autostoreGrid.hooks";
import { usePostFlagBinInventoryMovementsMutation } from "~/redux/warehouse/inventory.hooks";
import { GetBinResponse } from "~/types/api";

import { AlertInfo } from "./AlertInfo";
import { BinReconfigurationModal } from "./BinReconfigurationModal";
import { EscalateModal } from "./EscalateModal";
import { toggleBinReconfigurationModal } from "./binMaintenanceWorkstation.slice";

export function UnflagBinButton({
  binState,
  portId,
  restrictedItem,
  isReady,
  makeNextBinRequest
}: {
  binState: GetBinResponse | undefined;
  portId: number;
  isReady: boolean;
  restrictedItem?: boolean;
  makeNextBinRequest: (portId: number) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { successToast, errorToast } = useToast();
  const [deleteBinFlag] = useDeleteBinFlagMutation();
  const [postFlagBin] = usePostFlagBinInventoryMovementsMutation();
  const [closeBin] = useCloseBinMutation();

  const selectedWorkstation = useAppSelector(selectThisWorkstation);
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const { movementTaskState } = useAppSelector(
    (state) => state.binMaintenanceWorkstation
  );
  const isCleaningBin = movementTaskState === "CleaningBin";
  const { flags } = binState || {};
  const binNumber = binState?.binState.binId;

  const escalateMessageMap = {
    dirty: t("you are escalating to exterior maintenance. are you sure?"),
    damaged: t("if bin damage is present, please escalate"),
    misconfigured: null
  };
  const escalateMessage =
    flags?.length === 1
      ? escalateMessageMap[flags[0] as keyof typeof escalateMessageMap]
      : t("you are escalating to exterior maintenance. are you sure?");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cleanBinClicked, setCleanBinClicked] = useState(false);
  const [visualInspectClicked, setVisualInspectClicked] = useState(false);
  const [binReconfigClicked, setBinReconfigClicked] = useState(false);
  const [escalateAlertMessage, setEscalateAlertMessage] = useState<
    null | string
  >(null);
  const [isEscalateModalOpen, setIsEscalateModalOpen] = useState(false);

  const handleReleaseBin = async (escalate = false) => {
    if (!selectedWorkstation || !autostoreGridId || !binNumber) return;
    try {
      if (cleanBinClicked) {
        await deleteBinFlag({
          autostoreGridId,
          binNumber,
          reason: "dirty",
          workstationId: selectedWorkstation.id
        }).unwrap();
      }
      if (visualInspectClicked) {
        await deleteBinFlag({
          autostoreGridId,
          binNumber,
          reason: "damaged",
          workstationId: selectedWorkstation.id
        }).unwrap();
      }
      if (binReconfigClicked) {
        await deleteBinFlag({
          autostoreGridId,
          binNumber,
          reason: "misconfigured",
          workstationId: selectedWorkstation.id
        }).unwrap();
      }
      if (escalate) {
        await postFlagBin({
          autostoreGridId,
          workstationId: selectedWorkstation.id,
          reason: "Exterior Maintenance"
        }).unwrap();
        successToast(t("bin escalated"));
      } else {
        successToast(t("flag removed"));
      }
      await closeBin({
        autostoreGridId,
        portId,
        binNumber
      });
      makeNextBinRequest(portId);
      setIsModalOpen(false);
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  const resetState = () => {
    setIsModalOpen(false);
    setEscalateAlertMessage(null);
    setIsEscalateModalOpen(false);
    setCleanBinClicked(false);
    setVisualInspectClicked(false);
    setBinReconfigClicked(false);
  };

  return (
    <>
      <Button
        size="large"
        onClick={() => setIsModalOpen(true)}
        startIcon={<BookmarkFill20Px fill="white" />}
        disabled={!isCleaningBin || !isReady}
      >
        {`${t("unflag bin")} (${flags?.length})`}
      </Button>
      <Dialog open={isModalOpen} maxWidth="xs" onClose={() => resetState()}>
        <DialogTitle>{t("maintenance actions")}</DialogTitle>
        {restrictedItem && (
          <AlertInfo
            message={t("this bin had a restricted item and must be sanitized")}
          />
        )}
        <DialogContent sx={{ mt: 2 }}>
          <Stack direction="column" spacing={2} pt={1}>
            {flags?.map((flag) => {
              if (flag === "dirty") {
                return (
                  <Stack
                    key={flag}
                    direction="row"
                    spacing={5}
                    justifyContent="center"
                  >
                    <Typography alignContent="center" width={"140px"}>
                      {t("dirty bin")}
                    </Typography>
                    <Button
                      variant="subtle"
                      sx={{
                        width: "160px",
                        color: cleanBinClicked ? "success.main" : "black",
                        backgroundColor: cleanBinClicked
                          ? "success.light"
                          : "gray"
                      }}
                      size="large"
                      startIcon={
                        cleanBinClicked ? (
                          <Checkmark20Px fill="green" />
                        ) : (
                          <CheckmarkCircle20Px />
                        )
                      }
                      onClick={() => setCleanBinClicked(!cleanBinClicked)}
                    >
                      {cleanBinClicked ? t("bin cleaned") : t("clean bin")}
                    </Button>
                  </Stack>
                );
              }
              if (flag === "damaged") {
                return (
                  <Stack
                    key={flag}
                    direction="row"
                    spacing={5}
                    justifyContent="center"
                  >
                    <Typography alignContent="center" width={"140px"}>
                      {t("damaged bin")}
                    </Typography>
                    <Button
                      variant="subtle"
                      size="large"
                      sx={{
                        width: "160px",
                        color: visualInspectClicked ? "success.main" : "black",
                        backgroundColor: visualInspectClicked
                          ? "success.light"
                          : "gray"
                      }}
                      startIcon={
                        visualInspectClicked ? (
                          <Checkmark20Px fill="green" />
                        ) : (
                          <Search20Px />
                        )
                      }
                      onClick={() => {
                        setVisualInspectClicked(!visualInspectClicked);
                      }}
                    >
                      {t("visual inspect")}
                    </Button>
                  </Stack>
                );
              }
              if (flag === "misconfigured") {
                return (
                  <Stack
                    key={flag}
                    direction="row"
                    spacing={5}
                    justifyContent="center"
                  >
                    <Typography alignContent="center" width={"140px"}>
                      {t("misconfigured bin")}
                    </Typography>
                    <Button
                      variant="subtle"
                      size="large"
                      sx={{
                        width: "160px",
                        color: binReconfigClicked ? "success.main" : "black",
                        backgroundColor: binReconfigClicked
                          ? "success.light"
                          : "gray"
                      }}
                      startIcon={
                        binReconfigClicked ? (
                          <Checkmark20Px fill="green" />
                        ) : (
                          <GridDesign20Px />
                        )
                      }
                      onClick={() => dispatch(toggleBinReconfigurationModal())}
                    >
                      {t("reconfigure")}
                    </Button>
                  </Stack>
                );
              } else {
                return <></>;
              }
            })}
          </Stack>
        </DialogContent>
        <Collapse in={!!escalateAlertMessage}>
          {!!escalateAlertMessage && (
            <AlertInfo message={escalateAlertMessage} />
          )}
        </Collapse>
        <Collapse in={cleanBinClicked || visualInspectClicked}>
          <DialogActions sx={{ justifyContent: "center", padding: "30px" }}>
            <Button
              size="large"
              variant="serious"
              onClick={() =>
                escalateAlertMessage
                  ? setIsEscalateModalOpen(true)
                  : setEscalateAlertMessage(escalateMessage)
              }
              startIcon={<Warning20Px fill="white" />}
            >
              {t("escalate")}
            </Button>
            <Button
              size="large"
              onClick={() => handleReleaseBin()}
              startIcon={<CheckmarkCircle20Px fill="white" />}
            >
              {t("release bin")}
            </Button>
          </DialogActions>
        </Collapse>
      </Dialog>
      <EscalateModal
        open={isEscalateModalOpen}
        onClose={() => {
          setIsEscalateModalOpen(false);
          setEscalateAlertMessage(null);
        }}
        onRelease={() => handleReleaseBin(true)}
      />
      {binNumber && (
        <BinReconfigurationModal
          binNumber={binNumber}
          onConfirm={() => setBinReconfigClicked(true)}
        />
      )}
    </>
  );
}
