import { createSelector } from "@reduxjs/toolkit";

import { StoreState } from "~/redux/reducers";

export const selectClientIdReady = (state: StoreState) =>
  state.app.clientIdReady;

export const selectFlagsReady = (state: StoreState) => state.app.flagsReady;

export const selectInterceptorReady = (state: StoreState) =>
  state.app.interceptorReady;

export const selectClientConfigReady = (state: StoreState) =>
  state.app.clientConfigReady;

export const selectAppReady = createSelector(
  [
    selectClientIdReady,
    selectFlagsReady,
    selectInterceptorReady,
    selectClientConfigReady
  ],
  (clientIdReady, flagsReady, interceptorReady, clientConfigReady) =>
    clientIdReady && flagsReady && interceptorReady && clientConfigReady
);
