import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppState {
  clientIdReady: boolean;
  flagsReady: boolean;
  interceptorReady: boolean;
  clientConfigReady: boolean;
}

export const initialState: AppState = {
  clientIdReady: false, // to do
  flagsReady: false, // to do
  interceptorReady: false,
  clientConfigReady: false // to do
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setClientIdReady(state, action: PayloadAction<boolean>) {
      state.clientIdReady = action.payload;
    },
    setFlagsReady(state, action: PayloadAction<boolean>) {
      state.flagsReady = action.payload;
    },
    setClientConfigReady(state, action: PayloadAction<boolean>) {
      state.clientConfigReady = action.payload;
    },
    setInterceptorReady(state, action: PayloadAction<boolean>) {
      state.interceptorReady = action.payload;
    }
  }
});

export const {
  setFlagsReady,
  setInterceptorReady,
  setClientIdReady,
  setClientConfigReady
} = appSlice.actions;

export default appSlice.reducer;
